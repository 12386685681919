@use '@/styles/_utils.scss' as *;

.input {
  margin-top: getSpacing(2);
}

.link {
  margin-top: getSpacing(3);

  &.withPaddingBottom {
    padding-bottom: getSpacing(2);
  }
}

.anchor {
  @include text--link('small');
  color: var(--color-grey-500);
}
