@use '@/styles/_utils' as *;

.paragraph {
  margin: getSpacing(1) 0 0;
  @include text--description('small');
  color: var(--color-red);
  visibility: hidden;

  &::before {
    content: '';
    display: inline-block;
    width: 0;
  }
}

.isVisible {
  visibility: visible;
}

.isCentered {
  text-align: center;
}
