@use '@/styles/_utils.scss' as *;

%label {
  display: block;
  padding: 0 0 getSpacing(1);
  margin: 0;
  @include text--label('small');
}

%text {
  @include text--description('small');
}

$color: var(--color-black);
$color__placeholder: var(--color-grey-300);

$background: var(--color-white);

$border--default: 1px solid var(--color-grey-200);
$border--active: 1px solid var(--color-black);

$height: getSpacing(5);
$padding-x: getSpacing(1.5);
