@use '@/styles/_utils.scss' as *;
@use '../Fields.scss' as *;

.label {
  @extend %label;
}

.group {
  @extend %text;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 $padding-x;
  height: $height;

  background: $background;
  border: $border--default;
  border-radius: $border-radius--large;

  color: $color;

  &:focus-within {
    border: $border--active;
    outline: none;
  }
}

.withButton {
  height: auto;
}

.input {
  flex: 1 1 auto;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  color: inherit;
  border-radius: $border-radius--large;

  &::placeholder {
    color: $color__placeholder;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
}

.unit {
  color: var(--color-grey-400);
  border-radius: $border-radius--large;
}

.buttonWrapper {
  padding: getSpacing(0.5);
}

.buttonOuter {
  border: none;
}

.buttonInner {
  padding: getSpacing(1.5);
}
