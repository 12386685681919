@use '@/styles/_utils.scss' as *;

// ------------------------------------------------------------
//  SMALL
// ------------------------------------------------------------

.wrapper {
  min-height: 100vh;
  align-items: center;
  @include grid--xs();
}

.container {
  grid-column: 1 / -1;
}

.logo {
  width: getSpacing(8);
  margin: 0 auto getSpacing(5);
}

.image {
  width: 100%;
  height: auto;
}

.heading,
.description {
  text-align: center;
}

.description {
  margin-top: getSpacing(2);
}

.children {
  margin-top: getSpacing(3);
}

.success {
  text-align: center;
  color: var(--color-green-dark);
}

// ------------------------------------------------------------
//  MEDIUM
// ------------------------------------------------------------

@include media('m') {
  .wrapper {
    @include grid--md();
  }

  .container {
    grid-column: 2 / -2;
  }
}

// ------------------------------------------------------------
//  LARGE
// ------------------------------------------------------------

@include media('l') {
  .wrapper {
    @include grid--lg();
  }

  .container {
    grid-column: 5 / span 4;
  }
}
